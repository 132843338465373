@media (min-width: 768px) {


  #etp-timeline-metro {

    #etp-metro-main-parent {
      transform-style: preserve-3d;
      perspective: 1000px;
    }

    #etp-metro-main {
      display: block;

      overflow: visible;
      transition: transform 0.6s ease-in, opacity 1.0s ease, box-shadow 0.3s ease;
      //transform-origin: left;

      transform: rotateY(0) translateX(0);

      box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 8px;
      outline: 1px solid rgba(0, 0, 0, 0.1);
      background-color: rgba(0, 0, 0, 0.2);

      &.active {
        //transform-origin: left;
        opacity: 0;
        transform: translateZ(-200px);

      }

    }

    .etp-timeline-item-cover {
      overflow: visible;
      outline: 1px solid rgba(0, 0, 0, 0.1);
    }


  }
  #etp-timeline-scroll {
    display: none;

  }

  .etp-timeline-item-cover-image{
    transition: opacity 1s ease-out;
  }

  .etp-timeline-item-surround {
    overflow: visible;
    position: relative;
    display: grid;

    z-index: 1;


    &:hover {
      z-index: 1000;

      .etp-page-tabs {
        opacity: 1;
      }
    }
  }

  .etp-timeline-item {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s, left 0.5s, right 0.5s, maxWidth 0.5s, maxHeight 0.5s, box-shadow 0.3s ease;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);
    transform: translateZ(0);
    transform-style: preserve-3d;
    z-index: 0;
    .etp-edit-btn {
      opacity:0;
    }


    &:not(.active):hover {
      z-index: 10000;
      transform: translateZ(100px);
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
      .etp-timeline-item-infobar {
        top: -24px;
      }
      .etp-edit-btn {
        opacity:1;
      }
    }
  }
}


@media screen and (orientation: portrait) {
  .etp-timeline-item-iframe-rotated {
    transform-origin: top left;
    transform: rotate(90deg) translate(calc(0dvh), calc(-100dvw + 2rem));
  }
}

@media (max-width: 767px) {
  .etp-page-tabs {
    opacity: 1;
  }

  #etp-timeline-metro {
    display: none;
  }
  #etp-timeline-scroll {
    display: flex;

    .etp-timeline-item {
      border: 1px solid #f0f0f0;
      border-radius: 4px;
    }
  }


}

//The timeline preview photo will be scaled, so we blur to hide the pixelation
@media (min-width:340px) and (max-width: 767px) {
  .etp-timeline-item-cover {
    filter: blur(1px)
  }
}


.etp-timeline-message-body {
  align-content: flex-start;

}

.etp-timeline-item-infobar {
  color: rgba(0, 0, 0, 0.9);
  background-color: rgba(255, 255, 255, 1.0);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  top: 0;
  z-index: -10;
  min-height:24px;
  max-height: 24px;
  transition: top 0.3s;
  font-size: 12px;
}

@media (max-width: 768px) {
  .etp-timeline-item-infobar {
    position: absolute;
    box-shadow: none;
    z-index: 0;
    left: 0;
    right: 0;
    top: -16px;
    height: 16px;
    opacity: 1;
  }
  .etp-timeline-item {
    margin-top:16px;
  }

}


.etp-timeline-item:hover {
  .etp-page-tabs {
    opacity: 1;
  }

  .etp-timeline-item-infobar {
    opacity: 1;
  }

}


@media (max-width: 767px) {
  .etp-timeline-item-surround {
    min-width: calc(100vw - 2rem);
    max-width: calc(100vw - 2rem);
    min-height: calc(100dvh - 42px - 3rem);
    max-height: calc(100dvh - 42px - 3rem);
  }
}
