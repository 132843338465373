.etp-edit-btn-text {
  display: none;
  opacity: 0;
  transition: width 0.5s, opacity 0.5s;
}

.etp-edit-btn-icon {
  transition: width 0.5s;
}

.etp-edit-btn {
  transition: width 0.5s, opacity 0.3s;
  width: 3rem;
 &:hover {
   width: 10rem;
    .etp-edit-btn-text {
      margin-right:1rem;
      opacity: 1;
      display: inline;
    }
 }
}
